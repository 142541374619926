import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  LinkedAccount,
  Master,
} from '../../../models/plan-accounts/master/master';
import { CommonModule, DatePipe } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LinkedAccountsService } from '../../../services/linked-accounts/linked-accounts.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ModalService } from '../../../services/modal/modal.service';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { Modal } from 'bootstrap';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { Agency, Type } from '../../../models/commission-detail';
import { UserInfo } from '../../../models/user/user-info';
import { splitAgencyCode } from '../../../constants/general';
import { HttpErrorResponse } from '@angular/common/http';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
import { VISIBILITY } from '../../../enums/visibility';
import { handleError } from '../../../utils';

@Component({
  selector: 'app-modal-linked-accounts',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    DialogModule,
    ButtonModule,
  ],
  providers: [DatePipe],
  templateUrl: './modal-linked-accounts.component.html',
  styleUrl: './modal-linked-accounts.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ModalLinkedAccountsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() master: Master | undefined;
  @Input() modalRefDetail: Modal | undefined;
  form: FormGroup = new FormGroup({});
  accountsList: LinkedAccount[] = [];
  linkedAccounts: LinkedAccount[] | undefined;
  isAccountListDropdownVisible: boolean = false;
  private linkedAccountsSub$: Subscription | undefined;
  userInfo: UserInfo | undefined;
  isLoading: boolean = false;
  showDialog: boolean = false;
  isEditable: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private linkedAccountsService: LinkedAccountsService,
    private messageService: MessageService,
    private httpUtilisPlanOfAccount: HttpUtilisPlanOfAccounts,
    private datePipe: DatePipe,
    private planOfAccountsService: PlanOfAccountService,
    private modalService: ModalService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }
  }

  ngOnInit(): void {
    if (this.userInfo) {
      this.isEditable = this.userInfo.userFunctions.includes(
        VISIBILITY.PRIMANOTA_PIANODEICONTI_MASTRI_AGGIORNA
      );
    }
    this.initForm();

    this.linkedAccountsSub$ =
      this.linkedAccountsService.linkedAccounts$.subscribe((accounts) => {
        this.linkedAccounts = accounts.map((account) => {
          const formattedCarryDate = this.datePipe.transform(
            account.carryDate,
            'dd/MM/yyyy HH:mm'
          );
          return { ...account, formattedCarryDate };
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAccountsList();
  }

  ngOnDestroy(): void {
    if (this.linkedAccountsSub$) {
      this.linkedAccountsSub$.unsubscribe();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      account: new FormControl(undefined, [Validators.required]),
    });
  }

  getAccountsList() {
    if (
      this.master?.agency?.code &&
      this.master?.code &&
      this.master?.agencyCode
    ) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccount
        .getAccounts(this.master?.agencyCode, this.master.code.toString())
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.accountsList = response.accounts.map((account: any) => ({
              ...account,
              displayName: `${account.code} - ${account.name} - ${account.firstNoteManager.description} - ${account.ledger.description}`,
            }));
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            handleError(error, this.modalService, this.messageService);
            this.isAccountListDropdownVisible = false;
            this.initForm();
          },
        });
    }
  }

  showAccountListDropdown() {
    this.isAccountListDropdownVisible = !this.isAccountListDropdownVisible;
  }

  addAccount() {
    const selectedAccount = this.form.get('account')?.value;
    if (selectedAccount) {
      const newAccount = {
        ...selectedAccount,
        isNew: true,
        formattedCarryDate: this.datePipe.transform(
          selectedAccount.carryDate,
          'dd/MM/yyyy HH:mm'
        ),
      };
      this.linkedAccountsService.addLinkedAccount(newAccount);
      this.accountsList = this.accountsList.filter(
        (account) => account.code !== selectedAccount.code
      );
      this.form.reset();
    }
  }

  removeAccount(account: LinkedAccount) {
    if (account.isNew) {
      this.linkedAccountsService.removeLinkedAccount(account);

      this.accountsList.push({
        ...account,
        isNew: false,
      });

      this.accountsList.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  saveAddedAccounts() {
    if (this.master) {
      this.isLoading = true;

      const newAccounts = this.linkedAccounts?.filter(
        (account) => account.isNew
      );
      const accountsToSave =
        newAccounts?.map(({ isNew, ...rest }) => rest) ?? [];

      this.master?.linkedAccounts.push(...accountsToSave);
      this.httpUtilisPlanOfAccount.modifyMaster(this.master).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: 'Conti associati con successo',
          });

          this.updateMasterList();

          this.modalRefDetail?.hide();
          this.reset();
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            key: 'br',
            detail: error.error.message,
          });

          this.updateMasterList();
        },
      });
    }
  }

  updateMasterList() {
    if (this.userInfo?.agencyDescription) {
      this.httpUtilisPlanOfAccount
        .getMasters(
          this.userInfo?.agencyDescription.split(splitAgencyCode)[0],
          this.planOfAccountsService.ledgerAgency
        )
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.planOfAccountsService.mastersSub.next(response['ledgers']);
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  goBack() {
    const newAccounts = this.linkedAccounts?.filter((account) => account.isNew);
    if (newAccounts?.length === 0) {
      if (this.master) {
        this.reset();
        this.modalRefDetail?.hide();
      }
    } else {
      this.showDialog = true;
    }
  }

  checkNewAccountsAdded() {
    if (this.linkedAccounts?.filter((account) => account.isNew).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  closeModal() {
    this.showDialog = false;
    this.modalRefDetail?.hide();
    this.reset();
  }

  hideDialog() {
    this.showDialog = false;
  }

  reset() {
    this.isAccountListDropdownVisible = false;
    this.accountsList = [];
    this.initForm();
  }
}
