import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FirstNote } from '../../../../models/first-note/first-note';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FirstNoteService } from '../../../../services/first-note/first-note.service';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { forkJoin, Subscription } from 'rxjs';
import { Type } from '../../../../models/commission-detail';
import { getDirty, handleError } from '../../../../utils';
import { MessageService } from 'primeng/api';
import { ModalService } from '../../../../services/modal/modal.service';
import { ModalInfos } from '../../../../models/modal/modal-infos';
import { LinkedAccount } from '../../../../models/plan-accounts/master/master';
import { atLeastOneValidator } from '../../../../validators/give-and-get-validator';
import { Manager } from '../../../../models/plan-accounts/account/create-account-request';
import { HttpUtilisFirstNote } from '../../../../network/http-utilis-first-note';
import { FirstNoteFilter } from '../../../../enums/first-note';
import { UserInfo } from '../../../../models/user/user-info';
import { TYPE } from '../../../../enums/path-of-accounts';
import { splitAgencyCode } from '../../../../constants/general';
import { HttpUtilisPlanOfAccounts } from '../../../../network/http-utilis-plan-of-accounts';
import { Account } from '../../../../models/plan-accounts/account/account';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-modify-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    DropdownModule,
    CalendarModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './modify-modal.component.html',
  styleUrl: './modify-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ModifyModalComponent implements OnInit, OnDestroy {
  @Input() firstNote: FirstNote | undefined;
  modifySub$: Subscription | undefined;
  firstNoteMangerSub$: Subscription | undefined;
  accountSub$: Subscription | undefined;
  form: FormGroup = new FormGroup({});
  modeOptions: Type[] | undefined;
  accountOptions: Account[] | undefined;
  managerOptions: Manager[] | undefined;

  userInfo: UserInfo | undefined;
  isModify: boolean = false;
  isLoading: boolean = false;
  managerSelectedInForm: Manager | undefined;
  visible = false;
  firstTime = true;

  constructor(
    public firstNoteService: FirstNoteService,
    private messageService: MessageService,
    private modalService: ModalService,
    private httpServiceFirstNote: HttpUtilisFirstNote,
    private httpPlanOfAccounts: HttpUtilisPlanOfAccounts
  ) {
    this.form = new FormGroup(
      {
        operationID: new FormControl(null, Validators.required),
        registryDate: new FormControl(null, Validators.required),
        competenceDate: new FormControl(null, Validators.required),
        account: new FormControl(null, Validators.required),
        mode: new FormControl(null, Validators.required),
        description: new FormControl(null, Validators.required),
        revenueGive: new FormControl(null),
        expenseGet: new FormControl(null),
        policyNumber: new FormControl(null),
        firstNoteManager: new FormControl(null),
        producer: new FormControl(null),
        operationIdRev: new FormControl(null),
        manager: new FormControl(null, Validators.required),
      },
      { validators: atLeastOneValidator }
    );

    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }

    this.modifySub$ = this.firstNoteService.modifyModalVisibilitySub.subscribe(
      (value) => {
        this.visible = value;
      }
    );

    this.firstNoteMangerSub$ =
      this.firstNoteService.firstNoteMangerSub.subscribe((managerOperation) => {
        this.managerSelectedInForm = managerOperation.manager;
        this.resetForm();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['firstNote'].currentValue === undefined) {
      this.resetForm();
    } else {
      this.populateForm();
    }
  }

  ngOnDestroy(): void {
    this.modifySub$?.unsubscribe();
    this.firstNoteMangerSub$?.unsubscribe();
    this.accountSub$?.unsubscribe();
  }

  ngOnInit(): void {
    this.initState();
  }

  onSubmit(): void {
    if (this.form.valid) {
      if (this.isModify) {
        this.isLoading = true;
        const modifyRequest = this.createRequest(this.form.getRawValue());

        this.httpServiceFirstNote.modifyFirstNote(modifyRequest).subscribe({
          next: (_response) => {
            this.firstNoteService.filterSubject.next(FirstNoteFilter.ENTRAMBI);
            this.resetForm();
            this.visible = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              key: 'br',
              detail: 'Prima nota modificata con successo',
            });
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.visible = false;
            handleError(error, this.modalService, this.messageService);
            //this.modalService.showError(error);
          },
        });
      } else {
        const createRequest = this.createRequest(this.form.getRawValue());

        this.httpServiceFirstNote.createFirstNote(createRequest).subscribe({
          next: (_response) => {
            this.isLoading = false;
            this.visible = false;
            this.resetForm();
            this.firstNoteService.filterSubject.next(FirstNoteFilter.ENTRAMBI);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              key: 'br',
              detail: 'Prima nota creata con successo',
            });
          },
          error: (error) => {
            this.isLoading = false;
            this.visible = false;
            handleError(error, this.modalService, this.messageService);
            //this.modalService.showError(error);
          },
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  private createRequest(formValue: any) {
    formValue.producer = {
      code: formValue.producer,
      description: formValue.producer,
    };
    formValue.manuallyUpdated = true;
    formValue.revenueGive = formValue.revenueGive?.toString().replace(',', '.');
    formValue.expenseGet = formValue.expenseGet?.toString().replace(',', '.');

    return formValue;
  }

  populateForm(): void {
    if (
      this.firstNote != undefined &&
      this.firstNote.manuallyCreated != null &&
      this.managerSelectedInForm != undefined
    ) {
      this.isModify = true;
      this.isLoading = true;

      const modes = this.httpPlanOfAccounts.getAllInfos(
        TYPE.MODALITA,
        undefined,
        undefined,
        undefined,
        this.firstNote?.account?.type?.code
      );

      const accounts = this.httpPlanOfAccounts.getAccounts(
        this.managerSelectedInForm?.code ?? '',
        undefined,
        true
      );

      const manager = this.httpPlanOfAccounts.getAllInfos(
        TYPE.GESTORE_PN,
        undefined,
        this.managerSelectedInForm.code,
        this.userInfo?.node
      );

      forkJoin([modes, accounts, manager]).subscribe({
        next: ([modesResponse, accounResponse, managerResponse]) => {
          this.isLoading = false;
          this.modeOptions = modesResponse['types'];
          this.managerOptions = managerResponse['types'];
          this.accountOptions = Account.fromCall(accounResponse.accounts);
          const selectedAccount = this.accountOptions?.find(
            (x) => this.firstNote?.account?.code === x.code
          );

          const selectedManager = this.managerOptions?.find(
            (x) => this.firstNote?.manager.code === x.code
          );

          this.form.patchValue({
            operationID: this.firstNote?.operationID,
            registryDate: this.firstNote?.registryDate
              ? new Date(this.firstNote.registryDate)
              : null,
            competenceDate: this.firstNote?.competenceDate
              ? new Date(this.firstNote.competenceDate)
              : null,
            account: selectedAccount,
            mode: this.firstNote?.mode,
            description: this.firstNote?.description,
            revenueGive: this.firstNote?.revenueGive,
            expenseGet: this.firstNote?.expenseGet,
            policyNumber: this.firstNote?.policyNumber,
            firstNoteManager: this.firstNote?.firstNoteManager,
            manager: selectedManager,
            producer: this.firstNote?.producer.description,
            operationIdRev: this.firstNote?.operationIdRev,
          });
        },
        error: (error) => {
          this.isLoading = false;
          this.modalService.showError(error);
        },
      });

      this.setControlState(this.firstNote.manuallyCreated);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        key: 'br',
        detail: 'Informazioni relative alla prima nota non disponibili',
      });
    }
  }

  setControlState(isManually?: boolean): void {
    if (!this.isModify) {
      Object.keys(this.form.controls).forEach((controlName) => {
        if (controlName !== 'operationID') {
          this.form.get(controlName)?.enable();
        } else {
          this.form.get('operationID')?.disable();
        }
      });
    } else {
      if (!isManually) {
        this.form.get('operationID')?.disable();
        this.form.get('description')?.disable();
        this.form.get('revenueGive')?.disable();
        this.form.get('expenseGet')?.disable();
        this.form.get('policyNumber')?.disable();
        this.form.get('firstNoteManager')?.disable();
        this.form.get('producer')?.disable();
      } else {
        Object.keys(this.form.controls).forEach((controlName) => {
          if (controlName !== 'operationID') {
            this.form.get(controlName)?.enable();
          } else {
            this.form.get('operationID')?.disable();
          }
        });
      }
    }
  }

  resetForm(): void {
    this.isModify = false;
    this.form.reset({
      registryDate: new Date(),
      competenceDate: new Date(),
      firstNoteManager: this.managerSelectedInForm,
      manager: this.managerSelectedInForm,
    });

    if (this.managerSelectedInForm) {
      const account = this.httpPlanOfAccounts.getAccounts(
        this.managerSelectedInForm.code,
        undefined,
        true
      );

      const manager = this.httpPlanOfAccounts.getAllInfos(
        TYPE.GESTORE_PN,
        undefined,
        this.managerSelectedInForm.code,
        this.userInfo?.node
      );
      forkJoin([account, manager]).subscribe({
        next: ([accountResponse, managerResponse]) => {
          this.accountOptions = Account.fromCall(accountResponse.accounts);
          this.managerOptions = managerResponse['types'];
          this.setControlState();

          this.form.get('manager')?.disable();
        },
        error: (error) => {
          this.modalService.showError(error);
        },
      });
    }
  }

  isExpenseGetFilled(): boolean {
    return this.form.get('expenseGet')?.value;
  }

  isRevenueGiveFilled(): boolean {
    return this.form.get('revenueGive')?.value;
  }

  onChangeAccount(event: DropdownChangeEvent): void {
    const account: LinkedAccount = this.form.get('account')?.value;

    this.httpPlanOfAccounts
      .getAllInfos(
        TYPE.MODALITA,
        undefined,
        undefined,
        undefined,
        account.type.code
      )
      .subscribe({
        next: (response) => {
          this.modeOptions = response['types'];
          this.form.get('mode')?.reset();
        },
        error: (error) => {
          this.modalService.showError(error);
        },
      });
  }

  onClearAccount() {
    this.form.get('mode')?.reset();
  }

  onClearManager() {
    this.form.get('account')?.reset();
    this.form.get('mode')?.reset();
  }

  getDirty = getDirty;

  initState(): void {
    const agencyCode =
      this.userInfo?.agencyDescription.split(splitAgencyCode)[0];
    if (!this.isModify) {
      this.httpPlanOfAccounts
        .getAllInfos(
          TYPE.GESTORE_PN,
          undefined,
          agencyCode,
          this.userInfo?.node
        )
        .subscribe({
          next: (response) => {
            this.managerOptions = response['types'];
            this.isLoading = false;
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  onDialogHide() {
    this.firstNoteService.modifyModalVisibilitySub.next(false);
    if (this.isModify) {
      this.populateForm();
    }
  }
}
