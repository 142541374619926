import { Component, OnDestroy, OnInit } from '@angular/core';
import { CensusCoinsuranceService } from '../../../services/census-coinsurance/census-coinsurance.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { UserInfo } from '../../../models/user/user-info';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { Agency } from '../../../models/commission-detail';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../../../services/modal/modal.service';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CoinsuranceAgency } from '../../../models/census/census-coinsurance';
import { AddModifyCoinsuranceCensusModalComponent } from '../../../components/modals/census/add-modify-coinsurance-census-modal/add-modify-coinsurance-census-modal.component';
import { Subscription } from 'rxjs';
import { HttpUtilisCoinCensus } from '../../../network/http-coinsurance-census';
import { handleError } from '../../../utils';

@Component({
  selector: 'app-agency-search-census-screen',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    InputTextModule,
    ButtonModule,
    AddModifyCoinsuranceCensusModalComponent,
  ],
  templateUrl: './agency-search-census-screen.component.html',
  styleUrl: './agency-search-census-screen.component.scss',
})
export class AgencySearchCensusScreenComponent implements OnInit, OnDestroy {
  userInfo: UserInfo | undefined;
  filteredValues: Agency[] = [];
  isLoading: boolean = false;
  selectedCoinAgency: CoinsuranceAgency | undefined;
  updateCoinCollaborators$: Subscription | undefined;
  constructor(
    public censusCoinService: CensusCoinsuranceService,
    public httpUtilsCom: HttpUtilisCommissions,
    public httpCoinCensusUtities: HttpUtilisCoinCensus,
    public modalService: ModalService,
    private messageService: MessageService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );

      this.updateCoinCollaborators$ =
        this.censusCoinService.updateCollaborators.subscribe({
          next: () => {
            this.isLoading = true;

            this.httpCoinCensusUtities
              .getCoinAgencies(
                this.censusCoinService.censusForm.get('name')?.value,
                this.censusCoinService.censusForm.get('agency')?.value?.code
              )
              .subscribe({
                next: (response) => {
                  this.isLoading = false;

                  this.censusCoinService.censusSearchValues =
                    CoinsuranceAgency.fromJsonArray(
                      response.coinsuranceAgencies
                    );
                },
                error: (error: HttpErrorResponse) => {
                  this.isLoading = false;
                  handleError(error, this.modalService, this.messageService);
                },
              });
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            handleError(error, this.modalService, this.messageService);
          },
        });
    }
  }
  ngOnDestroy(): void {
    this.updateCoinCollaborators$?.unsubscribe();
  }
  ngOnInit(): void {
    this.censusCoinService.updateCollaborators.next();
    this.censusCoinService.showSearchTable = true;
  }

  onSubmit(): void {
    if (this.censusCoinService.censusForm.invalid) {
      this.censusCoinService.censusForm.markAllAsTouched();
      return;
    }

    this.censusCoinService.updateCollaborators.next();
    this.censusCoinService.showSearchTable = true;
  }

  public filterValues($event: AutoCompleteCompleteEvent): void {
    let query = $event.query;

    this.httpUtilsCom?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  public onAdd(): void {
    this.selectedCoinAgency = undefined;
    this.censusCoinService.visibilyAddModifyModal.next(true);
  }

  public onDelete(coinsurance: CoinsuranceAgency): void {
    if (coinsurance.idAgency === undefined) {
      return;
    }
    this.isLoading = true;
    this.httpCoinCensusUtities
      .deleteCoinAgency(coinsurance.idAgency)
      .subscribe({
        next: (_response) => {
          this.isLoading = false;
          this.messageService.add({
            key: 'br',
            severity: 'success',
            summary: 'Successo',
            detail: `Agenzia di coassicurazione eliminata con successo`,
          });
          this.censusCoinService.updateCollaborators.next();
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          handleError(error, this.modalService, this.messageService);
        },
      });
  }

  public onModify(coinsuranceAgency: CoinsuranceAgency): void {
    this.selectedCoinAgency = coinsuranceAgency;
    this.censusCoinService.visibilyAddModifyModal.next(true);
  }

  public getDisplayPoliciesText(displayPolicies: string): string {
    if (!displayPolicies) {
      return '';
    }

    return displayPolicies.length <= 40
      ? displayPolicies
      : displayPolicies.substring(0, 37) + '...';
  }
}
