import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CensusService } from '../../../services/census-collaborator/census.service';
import { InputTextModule } from 'primeng/inputtext';
import { UserInfo } from '../../../models/user/user-info';
import { CensusCollaborator } from '../../../models/census/census-collaborator';
import { AddModifyCensusModalComponent } from '../../../components/modals/census/add-modify-census-modal/add-modify-census-modal.component';
import { Subscription } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { HttpUtilisCensus } from '../../../network/http-census';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { Agency } from '../../../models/commission-detail';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { ModalService } from '../../../services/modal/modal.service';
import { MessageService } from 'primeng/api';
import { ModalInfos } from '../../../models/modal/modal-infos';
import { handleError } from '../../../utils';

@Component({
  selector: 'app-search-sensus-screen',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    AddModifyCensusModalComponent,
    ButtonModule,
    AutoCompleteModule,
  ],
  templateUrl: './search-census-screen.component.html',
  styleUrl: './search-census-screen.component.scss',
})
export class SearchCensusScreenComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  form = this.censusService.censusForm;
  userInfo: UserInfo | undefined;
  updateCollaborators$: Subscription | undefined;
  selectedCollaborator: CensusCollaborator | undefined;
  filteredValues: Agency[] = [];

  constructor(
    public censusService: CensusService,
    public httpUtilisCensus: HttpUtilisCensus,
    public httpUtilsCom: HttpUtilisCommissions,
    public modalService: ModalService,
    private messageService: MessageService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );

      this.updateCollaborators$ =
        this.censusService.updateCollaborators.subscribe({
          next: () => {
            this.isLoading = true;
            this.httpUtilisCensus
              .getCensus(
                this.form.get('name')?.value,
                this.form.get('agency')?.value
              )
              .subscribe({
                next: (response) => {
                  this.isLoading = false;
                  this.censusService.censusSearchValues =
                    CensusCollaborator.fromJsonArray(response.collaborators);
                },
                error: (error: HttpErrorResponse) => {
                  this.isLoading = false;
                  handleError(error, this.modalService, this.messageService);
                },
              });
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            handleError(error, this.modalService, this.messageService);
          },
        });
    } else {
      this.modalService.showModal(
        new ModalInfos('Errore', 'Informazioni utente mancanti', () => {
          window.open('/', '_self');
        })
      );
    }
  }
  ngOnDestroy(): void {
    this.updateCollaborators$?.unsubscribe();
    this.censusService.showSearchTable = false;
    this.censusService.censusForm.reset();
  }
  ngOnInit(): void {
    // if (this.userInfo?.isDirectionalUser) {
    // }
    //TODO: implement logic for directional user
    //TODO: implement logic for non-directional user

    this.censusService.updateCollaborators.next();
    this.censusService.showSearchTable = true;
  }

  onSubmit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.censusService.updateCollaborators.next();
    this.censusService.showSearchTable = true;
  }

  onModify(collaborator: CensusCollaborator) {
    this.selectedCollaborator = collaborator;
    this.censusService.visibilyAddModifyModal.next(true);
  }

  onAdd() {
    this.selectedCollaborator = undefined;
    this.censusService.visibilyAddModifyModal.next(true);
  }

  onDelete(collaborator: CensusCollaborator) {
    this.httpUtilisCensus.deleteCollaborator(collaborator.idColl).subscribe({
      next: () => {
        this.censusService.updateCollaborators.next();
        this.messageService.add({
          key: 'br',
          severity: 'success',
          summary: 'Successo',
          detail: 'Collaboratore eliminato con successo',
        });
      },
      error: (error: HttpErrorResponse) => {
        handleError(error, this.modalService, this.messageService);
      },
    });
  }

  filterValues($event: AutoCompleteCompleteEvent): void {
    let query = $event.query;

    this.httpUtilsCom?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }
}
