import { FormGroup } from '@angular/forms';
import { CommissionsFormService } from '../services/commissions-table/commissions-form.service';
import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ModalService } from '../services/modal/modal.service';
import { MessageService } from 'primeng/api';

export const getDirty = (form: any, formName: string): boolean | undefined => {
  return form.get(formName)?.invalid && form.get(formName)?.touched;
};

export const getType = (
  commissionFormService: CommissionsFormService
): string => {
  return commissionFormService.isProducer ? 'produttore' : 'coassicurazione';
};

export const exits = (value: any): boolean => {
  return value != null && value != undefined;
};

export const debugForm = (formGroup: FormGroup): void => {
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
    if (control instanceof FormGroup) {
      debugForm(control);
    } else if (control && control.invalid) {
      console.log(`Control: ${key}, Errors:`, control.errors);
    }
  });
};

export const handleError = (
  error: HttpErrorResponse,
  modalService: ModalService,
  messageService: MessageService
): void => {
  const code: string = error.status.toString();

  switch (code) {
    case '403':
    case '401': {
      modalService.showError(error);
      break;
    }
    default: {
      messageService.add({
        key: 'br',
        severity: 'error',
        summary: 'Errore',
        detail: error.error.message ?? 'Errore generico',
      });
    }
  }
};
