export enum Users {
  Prodotti = 'prodotti',
  Produttori = 'produttori',
  AgenziaCoassicurazione = 'agenziaCoassicurazione',
}

export enum VariazioneAliquota {
  CONS = 'Conservazione',
  VAR_GEN = 'Variazione generica',
}

export enum CondizioniAliquota {
  COND_000 = 'Data prima emissione antecedente il',
  COND_001 = 'Data prima emissione successiva al',
  COND_002 = 'Primo anno',
  COND_003 = 'Anni successivo al primo',
  COND_004 = 'Incassi successivi al primo',
  COND_005 = 'Mancanza provvigione di acquisto',
  COND_006 = 'Sostituita',
}

export enum VariationType {
  NEGATIVA = 'NEGATIVA',
  POSITIVA = 'POSITIVA',
}

export enum QueryTypeEnum {
  COINSURANCES = 'coinsurances',
  PRODUCERS = 'producers',
  COLLABORATOR = 'collaborators',
  PRODUCTS = 'products',
  CATEGORIES = 'categories',
  AGENCIES = 'agencies',
  AGENCY_COINSURANCE = 'agency_coinsurance',
}

export enum CATEGORY {
  AUTO = '0',
  ARD = '1',
}

export enum STORAGE_KEYS {
  BANK_RECORD_ID = 'bankRecordID',
  COMMISSION_ID = 'commissionID',
  ID_TOKEN = 'idToken',
  ACCESS_TOKEN = 'accessToken',
  USER_INFO = 'userInfo',
  PRODUCER = 'isProducer',
}
