import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { CoinsuranceAgency } from '../../models/census/census-coinsurance';

@Injectable({
  providedIn: 'root',
})
export class CensusCoinsuranceService {
  censusForm: FormGroup = new FormGroup({
    name: new FormControl(null),
    agency: new FormControl(null),
  });
  visibilyAddModifyModal: Subject<boolean> = new Subject<boolean>();
  updateCollaborators: Subject<void> = new Subject<void>();
  censusSearchValues: CoinsuranceAgency[] = [];
  showSearchTable: boolean = false;
  constructor() {}
}
