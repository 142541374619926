import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CASH_CONTROL_PATH, FIRST_NOTE_PATH } from '../../../enums/path';
import {
  DetailCashControl,
  SubAccountValue,
} from '../../../models/cash-control/detail-cash-control';

import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpUtilisCashControl } from '../../../network/http-utilis-cash-control';
import { ModalService } from '../../../services/modal/modal.service';
import { ModalInfos } from '../../../models/modal/modal-infos';
import { MessageService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { exits, handleError } from '../../../utils/commons-utilis';

@Component({
  selector: 'app-edit-cash-control-screen',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    TooltipModule,
    ProgressSpinnerModule,
    CustomCurrencyPipe,
    PanelModule,
    ReactiveFormsModule,
  ],
  templateUrl: './edit-cash-control-screen.component.html',
  styleUrl: './edit-cash-control-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class EditCashControlScreenComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  queryParamsSub$: Subscription | undefined;
  id: number | undefined;
  detailCashControl: DetailCashControl | undefined;
  modifiedCashControl: DetailCashControl | undefined;
  fromSearch: boolean = false;

  form: FormGroup = new FormGroup({
    accounts: new FormArray([
      new FormGroup({
        realValues: new FormArray([]),
      }),
    ]),
  });

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpCashControl: HttpUtilisCashControl,
    private modalService: ModalService,
    private messageService: MessageService,
    private titleService: Title
  ) {}

  ngOnDestroy(): void {
    this.queryParamsSub$?.unsubscribe();
  }

  ngOnInit(): void {
    this.queryParamsSub$ = this.activatedRouter.queryParams.subscribe(
      (params) => {
        if (params['id']) {
          this.fromSearch = params['fromSearch'] === 'true';
          this.id = params['id'];
          this.initState();
        }
      }
    );
  }

  goBack() {
    if (this.fromSearch) {
      this.router.navigate([CASH_CONTROL_PATH.SEARCH]);
    } else {
      this.router.navigate([CASH_CONTROL_PATH.DETAIL], {
        queryParams: { id: this.id },
      });
    }
  }

  onNavigateToFirstNote(subAccount: SubAccountValue, accountIndex: number) {
    this.titleService.setTitle('Prima Nota');

    return this.router
      .createUrlTree([FIRST_NOTE_PATH.NODE], {
        queryParams: {
          account:
            this.detailCashControl?.accountsCashControl[accountIndex].account
              .description,
          mode: subAccount.mode.description,
          date: subAccount.date,
          firstNoteManagerCode: this.detailCashControl?.firstNoteManager?.code,
          firstNoteManagerDescription:
            this.detailCashControl?.firstNoteManager.description,
          firstNoteManagerDetail:
            this.detailCashControl?.firstNoteManager.detail,
        },
      })
      .toString();
  }

  onRecalculate() {
    if (this.id) {
      this.isLoading = true;
      this.httpCashControl.recalculateCashControl(this.id).subscribe({
        next: (response: DetailCashControl) => {
          this.detailCashControl = DetailCashControl.fromJson(response);
          this.id = this.detailCashControl.id;
          this.initForm();
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: 'Ricalcolo effettuato con successo',
          });
        },
        error: (_error) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Errore',
            key: 'br',
            detail: 'Impossibile effettuare il ricalcolo ',
          });
        },
      });
    }
  }

  initForm() {
    const accountsArray = new FormArray<FormGroup>([]);

    this.detailCashControl?.accountsCashControl.forEach((account) => {
      const subAccountValuesArray = new FormArray<FormControl<number | null>>(
        []
      );
      account.subAccountValues.forEach((subAccount) => {
        subAccountValuesArray.push(new FormControl(subAccount.realValue));
      });

      accountsArray.push(
        new FormGroup({
          realValues: subAccountValuesArray,
        })
      );
    });

    this.form = new FormGroup({
      accounts: accountsArray,
    });
  }

  getAuditing(): boolean {
    let auditing = true;
    //TODO: implement if ITAS required the real time auditing check
    this.detailCashControl?.accountsCashControl.forEach(
      (account, accountIndex) => {
        account.subAccountValues.forEach((_subAccount, subAccountIndex) => {
          if (!this.showStatus(accountIndex, subAccountIndex)) {
            auditing = false;
            return;
          }
        });
      }
    );

    return auditing;
  }

  showStatus(accountIndex: number, subAccountIndex: number): boolean {
    const realValue: number =
      this.form.get('accounts')?.value[accountIndex].realValues[
        subAccountIndex
      ];

    const movements: number | undefined =
      this.detailCashControl?.accountsCashControl[accountIndex]
        .subAccountValues[subAccountIndex].movements;

    return realValue === movements;
  }

  onSave() {
    this.getValueForModify();

    if (this.modifiedCashControl && this.id) {
      this.isLoading = true;
      this.httpCashControl
        .modifyCashControl(this.modifiedCashControl, this.id)
        .subscribe({
          next: (response: DetailCashControl) => {
            this.detailCashControl = DetailCashControl.fromJson(response);
            this.initForm();
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              key: 'br',
              detail: 'Modifica effettuata con successo',
            });
            this.isLoading = false;
          },
          error: (error) => {
            this.isLoading = false;
            handleError(error, this.modalService, this.messageService);
          },
        });
    }
  }

  getValueForModify() {
    const formValues = this.form.value;

    this.modifiedCashControl = this.detailCashControl?.clone();

    formValues.accounts.forEach((account: any, accountIndex: number) => {
      account.realValues.forEach(
        (realValue: number, subAccountIndex: number) => {
          if (this.modifiedCashControl) {
            this.modifiedCashControl.accountsCashControl[
              accountIndex
            ].subAccountValues[subAccountIndex].realValue = realValue;
          }
        }
      );
    });
  }

  initState() {
    if (this.id === undefined) {
      this.modalService.showModal(
        new ModalInfos(
          'Errore',
          'Impossibile caricare la schermata di dettaglio',
          () => {
            window.open('/', '_self');
          }
        )
      );
      return;
    }

    this.isLoading = true;
    this.httpCashControl.getCashControlDetail(this.id).subscribe({
      next: (response: any) => {
        this.detailCashControl = DetailCashControl.fromJson(response);
        this.initForm();
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.modalService.showError(error);
      },
    });
  }

  getTotalRealValue(accountIndex: number): number {
    let total = 0;
    this.form
      .get('accounts')
      ?.value[accountIndex].realValues.forEach((value: number) => {
        total += value;
      });

    return total;
  }

  exist = exits;
}
