import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, concatMap, Observable, throwError } from 'rxjs';
import { QueryTypeEnum, STORAGE_KEYS } from '../enums/commission';
import { BankRecRowDetailResp } from '../models/bank-records/bank-record-detail-row-response';
import { BankRecordPDFResponse } from '../models/bank-records/bank-record-pdf-response';
import { BCRow } from '../models/bank-records/bank-records-row-search-response';
import { ConDeconsRequest } from '../models/bank-records/request/bank-records-con-decons-request';
import { BankRecAddOrRemoveRequest } from '../models/bank-records/request/bank-records-remove-add-request';
import { BankRecordAddRequest } from '../models/bank-records/request/bank-records-row-add-request';
import { CoinsuranceAgency, Producer } from '../models/commission-detail';
import { CustomError } from '../models/error/custom-error';
import { BankRecordsService } from '../services/bank-records/bank-records.service';
import { CommissionsFormService } from '../services/commissions-table/commissions-form.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilsBankRecords {
  constructor(
    private http: HttpClient,
    private bankRecordService: BankRecordsService,
    private commisionFormService: CommissionsFormService
  ) {}

  /**
   * Retrieves a list of bank records.
   *
   * @param collaborator - Optional parameter representing a collaborator (Producer or CoinsuranceAgency).
   * @param interval - Optional parameter representing an interval of dates.
   * @returns An Observable of an object containing statement rows and their corresponding data.
   */
  public getBankRecordList(
    collaborator?: Producer | CoinsuranceAgency,
    interval?: Date[]
  ): Observable<{ [statementRows: string]: any[] }> {
    let params: { [key: string]: number | string } = {};
    if (interval) {
      interval[0].setHours(15, 0, 0, 0);
      interval[1].setHours(15, 0, 0, 0);

      params['registrationFrom'] = interval[0].toISOString();
      params['registrationTo'] = interval[1].toISOString();
    }

    params['type'] = this.commisionFormService.isProducer
      ? QueryTypeEnum.COLLABORATOR
      : QueryTypeEnum.COINSURANCES;

    if (collaborator) params['id'] = collaborator.code;

    return this.http
      .get<{ [statementRows: string]: any[] }>(environment.URL_BANK_RECORD, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Retrieves the details of a bank record.
   *
   * @param idBankRecord - The ID of the bank record to retrieve.
   * @returns An observable that emits the bank record details.
   */
  public getBankRecordDetail(idBankRecord: number): Observable<any> {
    return this.http
      .get(environment.URL_BANK_RECORD + idBankRecord, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Deletes a bank record.
   *
   * @param idBankRecord - The ID of the bank record to delete.
   * @returns An Observable that emits the response from the server.
   */
  public deleteBankRecord(idBankRecord: number): Observable<any> {
    return this.http
      .delete(environment.URL_BANK_RECORD + idBankRecord, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public consolidatesAndDeconBankRecord(
    request: ConDeconsRequest,
    bankRecID: number
  ): Observable<any> {
    return this.http
      .put<ConDeconsRequest>(environment.URL_BANK_RECORD + bankRecID, request, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public getPDF(idBankRecord: number): Observable<BankRecordPDFResponse> {
    return this.http
      .get<BankRecordPDFResponse>(
        environment.URL_BANK_RECORD + idBankRecord + '/report',
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  //ROW

  public addOrRemoveRowsBankRec(
    request: BankRecAddOrRemoveRequest,
    bankRecID: number
  ) {
    return this.http
      .patch(environment.URL_BANK_RECORD + bankRecID + '/rows', request, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Retrieves bank record rows for a given collaborator.
   *
   * @param collaborator - The collaborator (either a Producer or CoinsuranceAgency) for which to retrieve bank record rows.
   * @param interval - Optional. An array of two Date objects representing the start and end dates of the interval to filter the bank record rows. If not provided, all rows will be retrieved.
   * @param idBankrecord - Optional. The ID of a specific bank record to retrieve. If provided, only the rows belonging to that bank record will be retrieved.
   * @param onlySuspendedRows - Optional. A boolean indicating whether to retrieve only the suspended rows. If set to true, only the suspended rows will be retrieved. If set to false or not provided, all rows will be retrieved.
   * @returns An Observable that emits an object containing the bank record rows.
   */
  public getRowsBankRecord(
    collaboratorID: string,
    interval?: Date[],
    idBankrecord?: number,
    onlySuspendedRows?: boolean
  ): Observable<{ [statementRows: string]: any[] }> {
    const type = this.commisionFormService.isProducer
      ? QueryTypeEnum.COLLABORATOR
      : QueryTypeEnum.COINSURANCES;

    let params: { [key: string]: number | string } = {};
    if (interval) {
      interval[0].setHours(15, 0, 0, 0);
      interval[1].setHours(15, 0, 0, 0);

      params['collectionDateFrom'] = interval[0].toISOString();
      params['collectionDateTo'] = interval[1].toISOString();
    }
    if (idBankrecord) params['statementId'] = idBankrecord;
    if (onlySuspendedRows)
      params['onlySuspendedRows'] = onlySuspendedRows ? 1 : 0;

    return this.http
      .get<{ [statementRows: string]: any[] }>(
        environment.URL_BANK_RECORD + type + '/' + collaboratorID,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
          params: params,
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Creates a new row.
   *
   * @param collaborator - The collaborator (Producer or CoinsuranceAgency) for which the row is being created.
   * @param addRequest - The request object containing the data for the new row.
   * @returns An Observable that emits the response from the server.
   */
  public createNewRowBankRecord(
    collaboratorID: number,
    addRequest: BankRecordAddRequest
  ): Observable<any> {
    const type = this.commisionFormService.isProducer
      ? QueryTypeEnum.COLLABORATOR
      : QueryTypeEnum.COINSURANCES;

    return this.http
      .post(
        environment.URL_BANK_RECORD + type + '/' + collaboratorID + '/rows',
        addRequest,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Retrieves the detailed information of a bank record row.
   *
   * @param collaborator - The collaborator (either a Producer or CoinsuranceAgency) for which to retrieve the bank record row detail.
   * @param rowID - The ID of the bank record row to retrieve.
   * @returns An Observable that emits the BankRecRowDetailResp object containing the detailed information of the bank record row.
   */
  public getRowDetailBankRec(
    collaboratorID: number,
    rowID: number
  ): Observable<BankRecRowDetailResp> {
    const type = this.commisionFormService.isProducer
      ? QueryTypeEnum.COLLABORATOR
      : QueryTypeEnum.COINSURANCES;

    return this.http
      .get<BankRecRowDetailResp>(
        environment.URL_BANK_RECORD +
          type +
          '/' +
          collaboratorID +
          '/rows/' +
          rowID,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Modifies a bank record row for a given collaborator.
   *
   * @param collaborator - The collaborator (either a Producer or CoinsuranceAgency) for whom the bank record row needs to be modified.
   * @param rowID - The ID of the bank record row to be modified.
   * @param modifiedRow - The modified bank record row details.
   * @returns An Observable that emits the modified bank record row details.
   */
  public modifyRowBankRec(
    collaboratorID: number,
    rowID: number,
    modifiedRow: BankRecRowDetailResp
  ): Observable<BankRecRowDetailResp> {
    const type = this.commisionFormService.isProducer
      ? QueryTypeEnum.COLLABORATOR
      : QueryTypeEnum.COINSURANCES;
    return this.http
      .put<BankRecRowDetailResp>(
        environment.URL_BANK_RECORD +
          type +
          '/' +
          collaboratorID +
          '/rows/' +
          rowID,
        modifiedRow,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public createNewDraft(collaboratorID: number, request: any) {
    const type = this.commisionFormService.isProducer
      ? QueryTypeEnum.COLLABORATOR
      : QueryTypeEnum.COINSURANCES;
    return this.http
      .post(
        environment.URL_BANK_RECORD + type + '/' + collaboratorID,
        request,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  // /statements-service/api/v1/statements/calculate
  public calculateRows(bcRows: BCRow[]): Observable<any> {
    const request = { statementRows: bcRows };

    return this.http
      .post(environment.URL_BANK_RECORD + 'calculate', request, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public calculateAndReload(
    bcRows: BCRow[],
    collaboratorID: string,
    idBankRecord?: number
  ): Observable<any> {
    return this.calculateRows(bcRows).pipe(
      concatMap((_responseFromCalculate) => {
        var interval = this.bankRecordService.bankRecordRowForm.get(
          'titleCollectionDate'
        )?.value;
        return this.getRowsBankRecord(collaboratorID, interval, idBankRecord);
      })
    );
  }

  public deleteAndReload(collaboratorID: number, rowID: number) {
    return this.deleteRowBankRec(collaboratorID, rowID).pipe(
      concatMap((_responseFromDelete) => {
        var interval = this.bankRecordService.bankRecordRowForm.get(
          'titleCollectionDate'
        )?.value;

        return this.getRowsBankRecord(collaboratorID.toString(), interval);
      })
    );
  }

  /**
   * Retrieves all the producers/coinsurrances based on the provided filter and ID.
   * @param filter - The filter to apply to the product query.
   * @param id - To return all the producers/coinsurrances not associated with the specific commission
   */
  public getCollabOrCoass(
    filter?: string,
    id?: number
  ): Observable<{ [items: string]: Producer[] | CoinsuranceAgency[] }> {
    let params: { [key: string]: number | string } = {};
    if (id) params['id'] = id;
    if (filter) params['filter'] = filter;

    const test = this.commisionFormService.isProducer
      ? QueryTypeEnum.COLLABORATOR
      : QueryTypeEnum.AGENCY_COINSURANCE;

    return this.http
      .get<{
        [items: string]: Producer[] | CoinsuranceAgency[];
      }>(environment.URL_COMMISSION + 'query/' + test, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Deletes a row from the bank records.
   *
   * @param collaboratorID - The ID of the collaborator.
   * @param rowID - The ID of the row to be deleted.
   */
  public deleteRowBankRec(
    collaboratorID: number,
    rowID: number
  ): Observable<any> {
    const type = this.commisionFormService.isProducer
      ? QueryTypeEnum.COLLABORATOR
      : QueryTypeEnum.COINSURANCES;

    return this.http
      .delete(
        environment.URL_BANK_RECORD +
          type +
          '/' +
          collaboratorID +
          '/rows/' +
          rowID,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }
}
