import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AgencyCashControl } from '../../models/cash-control/agency-cash';

@Injectable({
  providedIn: 'root',
})
export class CashControlService {
  showSearchTable = false;
  isFirstTime = true;
  tableValues: AgencyCashControl[] = [];
  searchForm = new FormGroup({
    name: new FormControl(null),
    interval: new FormControl<Date[]>([], [Validators.required]),
  });

  constructor() {
    const today = new Date();
    const fiftheenDaysAgo = new Date();

    fiftheenDaysAgo.setDate(today.getDate() - 15);
    this.searchForm.get('interval')?.setValue([fiftheenDaysAgo, today]);
  }
}
