import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Master, Type } from '../../../models/plan-accounts/master/master';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { getDirty, handleError } from '../../../utils';
import { TYPE } from '../../../enums/path-of-accounts';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { UserInfo } from '../../../models/user/user-info';
import { splitAgencyCode } from '../../../constants/general';
import { concatMap, forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../../../services/modal/modal.service';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { Modal } from 'bootstrap';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Agency } from '../../../models/commission-detail';
import { CreateMasterRequest } from '../../../models/plan-accounts/master/create-master-request';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
import { MessageService } from 'primeng/api';
import { ModalInfos } from '../../../models/modal/modal-infos';

@Component({
  selector: 'app-modal-add-master',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    DropdownModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './modal-add-master.component.html',
  styleUrl: './modal-add-master.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ModalAddMasterComponent implements OnInit, OnChanges {
  @Input() master: Master | undefined;
  @Input() modalRefAdd: Modal | undefined;
  form: FormGroup = new FormGroup({});
  typeOptions: Type[] | undefined;
  userInfo: UserInfo | undefined;
  code: string | undefined;
  filteredValues: Agency[] = [];
  isLoading: boolean = false;
  isModify: boolean = false;

  constructor(
    private httpServicePlanAccounts: HttpUtilisPlanOfAccounts,
    private modalService: ModalService,
    private httpUtilsCom: HttpUtilisCommissions,
    private planOfAccountsService: PlanOfAccountService,
    private messageService: MessageService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['master']) {
      if (this.master) {
        this.populateForm();
        this.isModify = true;
      } else {
        this.resetForm();
        this.isModify = false;
      }
    }
  }

  ngOnInit(): void {
    if (this.userInfo) {
      const getType$ = this.httpServicePlanAccounts.getAllInfos(
        TYPE.TIPOLOGIA,
        undefined,
        undefined,
        undefined
      );

      const getCode$ = this.httpServicePlanAccounts.getCode(
        TYPE.LEDGER,
        this.userInfo?.node,
        this.userInfo?.agencyDescription.split(splitAgencyCode)[0]
      );

      forkJoin([getCode$, getType$]).subscribe({
        next: ([codeResponse, typeResponse]) => {
          this.code = codeResponse['generatedCode'];
          if (!this.userInfo?.isDirectionalUser) {
            this.form.get('code')?.setValue(this.code);
          }
          this.typeOptions = typeResponse['types'];
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });

      this.form = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        code: new FormControl(
          this.userInfo.isDirectionalUser ? null : this.code,
          [Validators.required]
        ),
        type: new FormControl(null, [Validators.required]),
        agency: new FormControl(
          this.userInfo.isDirectionalUser
            ? null
            : {
                code: this.userInfo.node,
                description: this.userInfo.agencyDescription,
              },
          [Validators.required]
        ),
      });
      if (!this.userInfo.isDirectionalUser) {
        this.form.get('agency')?.disable();
      }

      this.form.get('code')?.disable();
    }
  }

  filterValues($event: AutoCompleteCompleteEvent): void {
    let query = $event.query;

    this.httpUtilsCom?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  goBack() {
    if (this.isModify) {
      this.populateForm();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.isModify && this.userInfo) {
        const modifyRequest: CreateMasterRequest = CreateMasterRequest.fromForm(
          this.form
        );
        this.httpServicePlanAccounts
          .modifyMaster(modifyRequest)
          .pipe(
            concatMap((_modifyResponse) => {
              return this.httpServicePlanAccounts.getMasters(
                this.userInfo!.agencyDescription.split(splitAgencyCode)[0],
                this.planOfAccountsService.ledgerAgency
              );
            })
          )
          .subscribe({
            next: (getMastersResponse) => {
              this.planOfAccountsService.mastersSub.next(
                getMastersResponse['ledgers']
              );
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                key: 'br',
                detail: 'Mastro modificato con successo',
              });
              this.isLoading = false;
            },
            error: (error) => {
              this.isLoading = false;
              handleError(error, this.modalService, this.messageService);
              //this.modalService.showError(error);
            },
          });
      } else {
        const createRequest: CreateMasterRequest = CreateMasterRequest.fromForm(
          this.form
        );
        if (this.userInfo?.isDirectionalUser) {
          this.httpServicePlanAccounts
            .createMaster(createRequest)
            .pipe(
              concatMap((_creteResponse) => {
                this.resetForm();
                return this.httpServicePlanAccounts.getMasters(
                  this.userInfo!.agencyDescription.split(splitAgencyCode)[0],
                  this.planOfAccountsService.ledgerAgency
                );
              })
            )
            .subscribe({
              next: (getMastersResponse) => {
                this.planOfAccountsService.mastersSub.next(
                  getMastersResponse['ledgers']
                );
                this.messageService.add({
                  severity: 'success',
                  summary: 'Successo',
                  key: 'br',
                  detail: 'Mastro creato con successo',
                });
                this.isLoading = false;
              },
              error: (error) => {
                this.isLoading = false;
                //this.modalService.showError(error);
                handleError(error, this.modalService, this.messageService);
              },
            });
        } else {
          this.httpServicePlanAccounts
            .createMaster(createRequest)
            .pipe(
              concatMap((_creteResponse) => {
                return this.httpServicePlanAccounts
                  .getCode(
                    TYPE.LEDGER,
                    this.userInfo!.node,
                    this.userInfo!.agencyDescription.split(splitAgencyCode)[0]
                  )
                  .pipe(
                    concatMap((codeResponse) => {
                      this.code = codeResponse['generatedCode'];
                      this.resetForm();

                      return this.httpServicePlanAccounts.getMasters(
                        this.userInfo!.agencyDescription.split(
                          splitAgencyCode
                        )[0],
                        this.planOfAccountsService.ledgerAgency
                      );
                    })
                  );
              })
            )
            .subscribe({
              next: (getMastersResponse) => {
                this.planOfAccountsService.mastersSub.next(
                  getMastersResponse['ledgers']
                );
                this.messageService.add({
                  severity: 'success',
                  summary: 'Successo',
                  key: 'br',
                  detail: 'Mastro creato con successo',
                });
                this.isLoading = false;
              },
              error: (error) => {
                this.isLoading = false;
                handleError(error, this.modalService, this.messageService);
                //this.modalService.showError(error);
              },
            });
        }
      }

      this.modalRefAdd?.hide();
    } else {
      this.form.markAllAsTouched();
    }
  }

  getTitle() {
    return this.master ? 'Modifica Mastro' : 'Nuovo Mastro';
  }

  getButtonTitle() {
    return this.master ? 'Modifica' : 'Crea';
  }

  populateForm() {
    this.form.setValue({
      name: this.master?.name,
      code: this.master?.code,
      type: this.master?.type,
      agency: {
        code: this.master?.agency.code,
        description: this.master?.agency.description,
      },
    });
    this.form.get('agency')?.disable();
    if (this.master?.linkedAccounts) {
      if (this.master?.linkedAccounts.length > 0) {
        this.form.get('type')?.disable();
      } else {
        this.form.get('type')?.enable();
      }
    }
  }

  resetForm(): void {
    if (this.userInfo) {
      this.form.reset({
        name: null,
        code: this.userInfo?.isDirectionalUser ? null : this.code,
        type: null,
        agency: this.userInfo.isDirectionalUser
          ? null
          : {
              code: this.userInfo.node,
              description: this.userInfo.agencyDescription,
            },
      });

      this.form.get('type')?.enable();
      if (this.userInfo.isDirectionalUser) {
        this.form.get('agency')?.enable();
      }
    } else {
      this.modalService.showModal(
        new ModalInfos('Errore', 'Informazioni utente mancanti', () => {
          window.open('/', '_self');
        })
      );
    }
  }

  onChangeAgency(event: AutoCompleteSelectEvent): void {
    if (this.userInfo?.isDirectionalUser) {
      const agency = event.value;
      this.httpServicePlanAccounts
        .getCode(
          TYPE.LEDGER,
          agency.code,
          agency.description.split(splitAgencyCode)[0]
        )
        .subscribe({
          next: (codeResponse) => {
            //this.code = codeResponse['generatedCode'];
            this.form.get('code')?.setValue(codeResponse['generatedCode']);
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
    }
  }

  onClearAgency(): void {
    if (this.userInfo?.isDirectionalUser) {
      this.form.get('code')?.setValue(null);
    }
  }

  getDirty = getDirty;
}
