import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SubAccount } from '../../../models/plan-accounts/sub-account/sub-account';
import { Modal } from 'bootstrap';
import { Agency, Type } from '../../../models/commission-detail';
import { UserInfo } from '../../../models/user/user-info';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { ModalService } from '../../../services/modal/modal.service';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { getDirty, handleError } from '../../../utils';
import { HttpErrorResponse } from '@angular/common/http';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TYPE } from '../../../enums/path-of-accounts';
import { splitAgencyCode } from '../../../constants/general';
import { MessageService } from 'primeng/api';
import { concatMap, Subscription } from 'rxjs';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';

@Component({
  selector: 'app-modal-add-sub-account',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    DropdownModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    InputSwitchModule,
  ],
  templateUrl: './modal-add-sub-account.component.html',
  styleUrl: './modal-add-sub-account.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ModalAddSubAccountComponent implements OnChanges, OnInit {
  @Input() subAccount: SubAccount | undefined;
  @Input() modalRefAdd: Modal | undefined;
  form: FormGroup = new FormGroup({});
  filteredValues: Agency[] = [];
  typeOptions: Type[] | undefined;
  accountOptions: Type[] | undefined;
  userInfo: UserInfo | undefined;
  isLoading = false;

  constructor(
    private httpUtilisPlanOfAccounts: HttpUtilisPlanOfAccounts,
    private modalService: ModalService,
    private httpUtilsCom: HttpUtilisCommissions,
    private messageService: MessageService,
    private planOfAccountsService: PlanOfAccountService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }

    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      code: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      node: new FormControl(null, Validators.required),
      account: new FormControl(null, Validators.required),
      agency: new FormControl(null, [Validators.required]),
      dirCollection: new FormControl(false),
    });
  }

  ngOnInit(): void {
    this.form.get('code')?.disable();
    this.form.get('node')?.disable();
    this.form.get('agency')?.disable();

    this.isLoading = true;
    this.httpUtilisPlanOfAccounts.getAllInfos(TYPE.MODALITA).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.typeOptions = response['types'];
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.modalService.showError(error);
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['subAccount']) {
      if (this.subAccount) {
        this.populateForm();
      }
    }
  }

  onSubmit() {
    if (this.form.valid && this.subAccount && this.userInfo) {
      this.isLoading = true;
      const modifiedAccount: SubAccount = this.form.getRawValue();
      modifiedAccount.agencyCode = this.subAccount?.agencyCode;

      this.httpUtilisPlanOfAccounts
        .modifySubAccount(modifiedAccount)
        .pipe(
          concatMap(() =>
            this.httpUtilisPlanOfAccounts.getSubAccount(
              this.userInfo!.agencyDescription.split(splitAgencyCode)[0],
              undefined,
              this.planOfAccountsService.subAccountAgency
            )
          )
        )
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.planOfAccountsService.subAccountsSub.next(
              SubAccount.fromCall(response['subaccounts'])
            );
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              key: 'br',
              detail: 'Sottoconto modificato con successo',
            });
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            handleError(error, this.modalService, this.messageService);
            //this.modalService.showError(error);
          },
        });
      this.modalRefAdd?.hide();
    } else {
      this.form.markAllAsTouched();
    }
  }

  filterValues($event: AutoCompleteCompleteEvent): void {
    let query = $event.query;

    if (query.length < 3) {
      this.filteredValues = [];
      return;
    }

    this.httpUtilsCom?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  goBack() {
    this.populateForm();
  }

  getDirty = getDirty;

  populateForm() {
    this.form.setValue({
      name: this.subAccount?.name,
      code: this.subAccount?.code,
      type: this.subAccount?.type,
      node: this.subAccount?.pathNodo,
      account: this.subAccount?.account ?? null,
      agency: this.subAccount?.agency,
      dirCollection: this.subAccount?.dirCollection,
    });

    this.onChangeType();
  }

  onChangeType(): void {
    const agency = this.form.get('agency')?.value;
    const type = this.form.get('type')?.value;

    if (agency && type) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccounts
        .getAllInfos(
          TYPE.ACCOUNT,
          type?.code,
          agency?.description.split(splitAgencyCode)[0],
          agency?.code
        )
        .subscribe({
          next: (response) => {
            this.accountOptions = response['types'];
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.modalService.showError(error);
          },
        });
    }
  }

  onClearType(): void {
    this.form.get('account')?.setValue(null);
  }
}
