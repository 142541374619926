import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CommissionsFormService } from '../services/commissions-table/commissions-form.service';
import { QueryTypeEnum, STORAGE_KEYS } from '../enums/commission';
import {
  Agency,
  CoinsuranceAgency,
  CommissionDetail,
  Producer,
  Product,
  Category,
} from '../models/commission-detail';
import { CommissionTableRequest } from '../models/commission-table/commission_table_request';
import { CommissionTableResponse } from '../models/commission-table/commission_table_response';
import { ErrorAssociate } from '../models/associate-dissociate/asso-disso-error';
import { AssoDissoRequest } from '../models/associate-dissociate/asso-disso-request';
import {
  catchError,
  concatMap,
  interval,
  map,
  Observable,
  of,
  switchMap,
  takeWhile,
  throwError,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { CommissionData } from '../models/commission/commission-data';
import { CommissionTableEditRequest } from '../models/commission-table/commission-table-edit-request';
import { CustomError } from '../models/error/custom-error';
import { AssoDissoWrapper } from '../models/associate-dissociate/asso-disso-wrapper';
import { ParzialErrorsResponse } from '../models/associate-dissociate/parzial-errors-response';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilisCommissions {
  constructor(
    private http: HttpClient,
    private commissionFormService: CommissionsFormService
  ) {}
  /**
   * Retrieves all the producers/coinsurrances based on the provided filter and ID.
   * @param filter - The filter to apply to the product query.
   * @param id - To return all the producers/coinsurrances not associated with the specific commission
   */
  public getProducerOrCoass(
    filter?: string,
    id?: number
  ): Observable<{ [items: string]: Producer[] | CoinsuranceAgency[] }> {
    let params: { [key: string]: number | string } = {};
    if (id) params['id'] = id;
    if (filter) params['filter'] = filter;
    const test = this.commissionFormService.isProducer
      ? QueryTypeEnum.PRODUCERS
      : QueryTypeEnum.COINSURANCES;

    return this.http
      .get<{
        [items: string]: Producer[] | CoinsuranceAgency[];
      }>(environment.URL_COMMISSION + 'query/' + test, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Retrieves all the products based on the provided filter and ID.
   * @param filter - The filter to apply to the product query.
   * @param id - To return all the products not associated with the specific commission
   * @param categoryCode - To return all the products associated with the specific category.
   */
  public getProduct(
    filter?: string,
    id?: number,
    categoryCode?: string
  ): Observable<{ [items: string]: Product[] }> {
    let params: { [key: string]: number | string } = {};
    if (id) params['id'] = id;
    if (filter) params['filter'] = filter;
    if (categoryCode) params['category'] = categoryCode;
    return this.http
      .get<{ [items: string]: Product[] }>(
        environment.URL_COMMISSION + 'query/' + QueryTypeEnum.PRODUCTS,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
          params: params,
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Retrieves agencies based on the provided filter.
   * @param filter - The filter to apply when retrieving agencies.
   */
  public getAgencies(
    filter: string
  ): Observable<{ [items: string]: Agency[] }> {
    return this.http
      .get<{ [items: string]: Agency[] }>(
        environment.URL_COMMISSION + 'query/' + QueryTypeEnum.AGENCIES,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
          params: { filter: filter },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public getCategories(): Observable<{ [items: string]: Category[] }> {
    return this.http
      .get<{ [items: string]: Category[] }>(
        environment.URL_COMMISSION + 'query/' + QueryTypeEnum.CATEGORIES,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public getAllInfos(type: string, filter?: string): Observable<any> {
    const params: { [key: string]: string } = {};
    if (filter) params['filter'] = filter;

    return this.http
      .get(environment.URL_COMMISSION + 'query/' + type, {
        params: params,
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public getCommissionData(
    category: Category,
    codTipologia?: string
  ): Observable<CommissionData> {
    let params: { [key: string]: string | number } = {};
    if (codTipologia) params['codTipologia'] = codTipologia;
    if (category) params['codRamo'] = category.code;

    return this.http
      .get<CommissionData>(environment.URL_COMMISSION + 'commissionsType', {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Retrieves commissions based on the provided request parameters.
   * @param commissioRequest - The commission request object.
   * @param sort - The sort parameter for the request.
   * @param size - The size parameter for pagination.
   * @param page - The page parameter for pagination.
   */
  public getCommissions(
    commissioRequest: CommissionTableRequest,
    sort?: string,
    size?: number,
    page?: number
  ): Observable<CommissionTableResponse> {
    let params: { [key: string]: string | number } = {};
    if (sort) params['sort'] = sort;
    if (size) params['size'] = size;
    if (page) params['page'] = page;

    return this.http
      .post<CommissionTableResponse>(
        environment.URL_COMMISSION + 'table',
        commissioRequest,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            } `,
          },
          params: params,
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public deleteCommission(
    id: number
  ): Observable<{ [status: string]: string }> {
    let type: string = this.commissionFormService.isProducer
      ? QueryTypeEnum.PRODUCERS
      : QueryTypeEnum.COINSURANCES;

    return this.http
      .delete<{ [status: string]: string }>(
        environment.URL_COMMISSION + type + '/' + id,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  public getCommissionDetail(id: number): Observable<CommissionDetail> {
    let type: string = this.commissionFormService.isProducer
      ? QueryTypeEnum.PRODUCERS
      : QueryTypeEnum.COINSURANCES;

    return this.http
      .get<CommissionDetail>(environment.URL_COMMISSION + type + '/' + id, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  public sendCommissionDetail(
    commission: CommissionTableEditRequest
  ): Observable<{ [status: string]: string }> {
    let type: string = this.commissionFormService.isProducer
      ? QueryTypeEnum.PRODUCERS
      : QueryTypeEnum.COINSURANCES;

    this.fixDates(commission);
    return this.http
      .put<{ [status: string]: string }>(
        environment.URL_COMMISSION + type,
        commission,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Associates or dissociates a product, producer, or coinsurance with a commission.
   *
   * @param isProducts - A boolean indicating whether the association or dissociation is for products.
   * @param id - The ID of the commission.
   * @param assoDisso - The request object containing the association or dissociation data.
   */
  public associateDissociate(
    isProducts: boolean,
    id: number,
    assoDisso: AssoDissoRequest
  ): Observable<ErrorAssociate> {
    let type: string = isProducts ? QueryTypeEnum.PRODUCTS : '';
    if (type === '')
      type = this.commissionFormService.isProducer
        ? QueryTypeEnum.PRODUCERS
        : QueryTypeEnum.COINSURANCES;

    return this.http
      .patch<ErrorAssociate>(
        environment.URL_COMMISSION + type + '/' + id,
        assoDisso,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  public addOrModifyCommission(
    commission: CommissionTableEditRequest,
    associoDissoWrap: AssoDissoWrapper
  ) {
    this.fixDates(commission);
    const assoReq: AssoDissoRequest = this.commissionFormService.isProducer
      ? new AssoDissoRequest(
          associoDissoWrap.values['assoproducers'],
          associoDissoWrap.values['dissoproducers']
        )
      : new AssoDissoRequest(
          associoDissoWrap.values['assocoinsurances'],
          associoDissoWrap.values['dissocoinsurances']
        );

    return this.sendCommissionDetail(commission)
      .pipe(
        concatMap((sendResponse) => {
          const commissionId = Number(sendResponse['commissionId']);
          return this.associateDissociate(
            true,
            commissionId,
            new AssoDissoRequest(
              associoDissoWrap.values['assoproducts'],
              associoDissoWrap.values['dissoproducts']
            )
          ).pipe(
            concatMap((assoProductResponse: any) => {
              return this.associateDissociate(
                false,
                commissionId,
                assoReq
              ).pipe(
                map((assoProducerResponse: any) => {
                  return new ParzialErrorsResponse(
                    commissionId,
                    assoProducerResponse['errors'],
                    assoProductResponse['errors']
                  );
                })
              );
            })
          );
        })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
  /*
  public getlocalStorageValue(): Observable<any | null> {
    let tries: number = 0;

    return interval(100).pipe(
      switchMap(() => {

        let key = localStorage.getItem(STORAGE_KEYS.ID_TOKEN);
        let key2 = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
        console.log(key, key2);
        return of({ key: key, key2: key2 });
      }),
      takeWhile((value) => value?.key === null || value?.key2 === null, true)
    );
  }
    */

  private fixDates(commission: CommissionTableEditRequest): void {
    const dateStart = new Date(commission.validityStartDate);
    const dateEnd = commission.validityEndDate
      ? new Date(commission.validityEndDate)
      : null;

    dateStart.setHours(15, 0, 0, 0);
    if (dateEnd) dateEnd.setHours(15, 0, 0, 0);

    commission.validityStartDate = dateStart.toISOString();
    commission.validityEndDate = dateEnd ? dateEnd.toISOString() : null;
  }
}
