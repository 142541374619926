<div class="mt-2" *ngIf="!isLoading">
  <div *ngIf="!embeddedIsEmpty()">
    <p-table
      [value]="this.bankRecords"
      dataKey="id"
      [scrollable]="true"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [tableStyle]="{ 'min-width': '60rem' }"
      [rows]="10"
      styleClass=" table-commission text-center"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="registrationDate" style="width: auto">
            Creazione
            <p-sortIcon field="registrationDate"></p-sortIcon>
          </th>
          <th pSortableColumn="fullName" style="width: auto">
            Collaboratore
            <p-sortIcon field="fullName"></p-sortIcon>
          </th>
          <th pSortableColumn="id" style="width: auto">
            N. Estratto conto <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="totStatementLordo" style="width: auto">
            Totale <p-sortIcon field="totStatementLordo"></p-sortIcon>
          </th>
          <th pSortableColumn="consolidated" style="width: auto">
            Stato
            <p-sortIcon field="consolidatingUser"></p-sortIcon>
          </th>
          <th pSortableColumn="consolidatingUser" style="width: auto">
            Utente consolidamento
            <p-sortIcon field="consolidatingUser"></p-sortIcon>
          </th>
          <th pSortableColumn="lastUpdateUser" style="width: auto">
            Utente ultima modifica
            <p-sortIcon field="lastUpdateUser"></p-sortIcon>
          </th>
          <th pSortableColumn="lastUpdateDate" style="width: auto">
            Ultima modifica
            <p-sortIcon field="lastUpdateDate"></p-sortIcon>
          </th>

          <th style="width: 6rem"></th>
        </tr>
        <tr>
          <th></th>
          <th>
            <p-columnFilter
              field="{{
                commissionFormService.isProducer ? 'fullName' : 'desCoas'
              }}"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="id" matchMode="contains" [showMenu]="false">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-bankRecord let-i="rowIndex">
        <tr>
          <td class="text-center">
            {{ bankRecord.registrationDate | date : "dd/MM/yyyy" : "UTC+2" }}
          </td>
          <td class="text-center">
            {{
              commissionFormService.isProducer
                ? bankRecord.fullName
                : bankRecord.desCoas
            }}
          </td>
          <td class="text-center">{{ bankRecord.id }}</td>
          <td>
            {{ bankRecord.totStatementLordo | customCurrency }}
          </td>
          <td *ngIf="bankRecord.consolidated" class="text-center">
            <p-tag value="Consolidato" />
          </td>
          <td
            class="d-flex justify-content-center"
            *ngIf="!bankRecord.consolidated"
          >
            <p-tag value="Bozza" class="tag-bozza" />
          </td>
          <td class="text-center">{{ bankRecord.consolidatingUser }}</td>
          <td class="text-center">{{ bankRecord.lastUpdateUser }}</td>

          <td>
            {{
              bankRecord.lastUpdateDate | date : "dd/MM/yyyy HH:mm" : "UTC+2"
            }}
          </td>

          <td>
            <div class="row text-end">
              <div *ngIf="!bankRecord.consolidated" class="col-md">
                <a
                  [href]="onModifyRedirect(bankRecord.id)"
                  (click)="onModify($event, bankRecord.id)"
                >
                  <i class="pi pi-pen-to-square icon" title="Modifica"></i>
                </a>
              </div>
              <div class="col-md">
                <a
                  [href]="onDetailRedirect(bankRecord.id)"
                  (click)="onDetail($event, bankRecord.id)"
                >
                  <i class="pi pi-address-book icon" title="Dettaglio"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorcenter"> </ng-template>
    </p-table>
  </div>
  <div *ngIf="embeddedIsEmpty()">
    <p class="text-center text">La ricerca non ha prodotto nessun risultato</p>
  </div>
</div>
<div class="mt-2 text-center" *ngIf="isLoading">
  <p-progressSpinner ariaLabel="loading" />
</div>
