<div class="commission-form mb-5">
  <form [formGroup]="this.censusCoinService.censusForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="name">Nome Agenzia coassicuratrice</label>
        <input
          pInputText
          type="text"
          id="name"
          formControlName="name"
          class="commission-input"
        />
      </div>

      <div *ngIf="this.userInfo?.isDirectionalUser" class="col-lg-4 col-md-12">
        <label for="agency">Agenzia</label>
        <p-autoComplete
          styleClass="autocomplete-dropdown"
          formControlName="agency"
          showClear="true"
          [dropdown]="true"
          [suggestions]="filteredValues"
          (completeMethod)="filterValues($event)"
          placeholder="Seleziona..."
          showEmptyMessage="false"
          field="description"
        />
      </div>
      <div
        class="col-lg-4 col-md-12 d-flex justify-content-start align-items-end"
      >
        <button class="btn btn-primary" type="submit">
          <strong>CERCA</strong>
        </button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="isLoading" class="text-center spinner-overlay">
  <p-progressSpinner ariaLabel="loading" />
</div>
<div class="row">
  <div class="col-6 mb-3">
    <p-button
      type="button"
      icon="pi pi-plus"
      styleClass="p-button-text"
      label="Crea"
      (click)="onAdd()"
    ></p-button>
  </div>
</div>
<div
  *ngIf="
    this.censusCoinService.showSearchTable &&
    !isLoading &&
    this.censusCoinService.censusSearchValues.length > 0
  "
>
  <p-table
    [value]="this.censusCoinService.censusSearchValues"
    dataKey="id"
    [scrollable]="true"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass="table-commission text-center"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="nameAgency" style="width: auto">
          Agenzia
          <p-sortIcon field="nameAgency"></p-sortIcon>
        </th>
        <th pSortableColumn="active" style="width: auto">
          Attivo <p-sortIcon field="active"></p-sortIcon>
        </th>
        <th pSortableColumn="displayCompanies" style="width: auto">
          Compagnie coassicurazione
          <p-sortIcon field="displayCompanies"></p-sortIcon>
        </th>
        <th pSortableColumn="displayPolicies" style="width: auto">
          Polize gestite
          <p-sortIcon field="displayPolicies"></p-sortIcon>
        </th>
        <th></th>
      </tr>
      <tr>
        <th>
          <p-columnFilter
            field="nameAgency"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th></th>
        <th>
          <p-columnFilter
            field="displayCompanies"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="displayPolicies"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-coinsurance let-i="rowIndex">
      <tr>
        <td class="text-center">
          {{ coinsurance.nameAgency ?? "" }}
        </td>
        <td class="d-flex align-items-center justify-content-center">
          <i
            *ngIf="coinsurance.active"
            class="pi pi-check-circle icon-green"
          ></i>
          <i
            *ngIf="!coinsurance.active"
            class="pi pi-times-circle icon-red"
          ></i>
        </td>
        <td class="text-center">
          {{ coinsurance.displayCompanies ?? "" }}
        </td>
        <td class="text-center">
          {{ getDisplayPoliciesText(coinsurance.displayPolicies) }}
        </td>
        <td>
          <a (click)="onModify(coinsurance)">
            <i class="pi pi-pen-to-square icon col-md-6" title="Modifica"></i>
          </a>

          <a (click)="onDelete(coinsurance)">
            <i class="pi pi-trash icon icon col-md-6" title="Elimina"></i>
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div
  *ngIf="
    this.censusCoinService.censusSearchValues?.length === 0 &&
    this.censusCoinService.showSearchTable
  "
>
  <p class="text-center text">La ricerca non ha prodotto nessun risultato</p>
</div>
<app-add-modify-coinsurance-census-modal
  [coinsuranceAgency]="this.selectedCoinAgency"
></app-add-modify-coinsurance-census-modal>
