import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { STORAGE_KEYS } from '../enums/commission';
import { catchError, Observable, throwError } from 'rxjs';
import { CustomError } from '../models/error/custom-error';
import { CoinsuranceAgency } from '../models/census/census-coinsurance';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilisCoinCensus {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves a list of coinsurrance agencies based on the provided parameters.
   *
   * @param {string} [name] - The name of the agency to search for.
   * @param {string} [agencyNode] - The agency node to search for.
   * @returns {Observable<any>} An observable containing the response from the HTTP GET request.
   *
   * @throws {CustomError} If an error occurs during the HTTP request.
   */
  public getCoinAgencies(name?: string, agencyNode?: string): Observable<any> {
    const params: { [key: string]: string } = {};
    if (name) {
      params['nameAgencyCoass'] = name;
    }

    if (agencyNode) {
      params['agency'] = agencyNode;
    }

    return this.http
      .get(environment.URL_COMMISSION + `agenzie`, {
        params: params,
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Deletes a coinsurance agency by its ID.
   *
   * @param {number} id - The ID of the coin agency to delete.
   * @returns {Observable<any>} An observable that emits the server's response.
   *
   * @throws {CustomError} Throws an error if the HTTP request fails.
   */
  public deleteCoinAgency(id: number): Observable<any> {
    return this.http
      .delete(environment.URL_COMMISSION + `agenzie/${id}`, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Adds or modifies a coinsurance agency.
   *
   * This method sends a PUT request to the specified endpoint to add or modify
   * a coinsurance agency. The request includes authorization headers with tokens
   * retrieved from local storage.
   *
   * @param {CoinsuranceAgency} agency - The coinsurance agency object to be added or modified.
   * @returns {Observable<any>} An observable that emits the server's response.
   *
   * @throws {CustomError} If an error occurs during the HTTP request.
   */
  public addOrModifyCoinAgency(agency: CoinsuranceAgency): Observable<any> {
    return this.http
      .put(environment.URL_COMMISSION + `agenzie`, agency, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }
}
