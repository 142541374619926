import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Master } from '../../../models/plan-accounts/master/master';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { UserInfo } from '../../../models/user/user-info';
import { ModalService } from '../../../services/modal/modal.service';
import { ModalInfos } from '../../../models/modal/modal-infos';
import { Router } from '@angular/router';
import { ModalAddMasterComponent } from '../../../components/modals/modal-add-master/modal-add-master.component';
import Modal from 'bootstrap/js/dist/modal';
import { MessageService } from 'primeng/api';
import { ModalLinkedAccountsComponent } from '../../../components/modals/modal-linked-accounts/modal-linked-accounts.component';
import { LinkedAccountsService } from '../../../services/linked-accounts/linked-accounts.service';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { concatMap, Subscription } from 'rxjs';
import { splitAgencyCode } from '../../../constants/general';
import { VISIBILITY } from '../../../enums/visibility';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Agency } from '../../../models/commission-detail';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TYPE } from '../../../enums/path-of-accounts';
import { getDirty, handleError } from '../../../utils';

@Component({
  selector: 'app-master-screen',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    CustomCurrencyPipe,
    ModalAddMasterComponent,
    ModalLinkedAccountsComponent,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
  ],
  templateUrl: './master-screen.component.html',
  styleUrl: './master-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MasterScreenComponent implements OnInit, OnDestroy {
  masters: Master[] | undefined;
  selectedMasters: Master[] = [];
  userInfo: UserInfo | undefined;
  modalAdd: any | undefined;
  modifiedMaster: Master | undefined;
  modalLinkedAccounts: any | undefined;
  modalRefDetail: Modal | undefined;
  modalRefAdd: Modal | undefined;
  mastersSub$: Subscription | undefined;
  isLoading: boolean = false;
  isEditable: boolean = false;
  showTable: boolean = false;
  agenciesOptions: Agency[] | undefined;
  form: FormGroup | undefined;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private messageService: MessageService,
    private linkedAccountsService: LinkedAccountsService,
    private planOfAccountsService: PlanOfAccountService,
    private httpUtilisPlanOfAccounts: HttpUtilisPlanOfAccounts
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage?.getItem('userInfo')!)
      );

      this.form = new FormGroup({
        agency: new FormControl(null, Validators.required),
      });
    } else {
      this.modalService.showModal(
        new ModalInfos(
          'Attenzione',
          'Informazioni utente non disponibili',
          () => {
            this.router.navigate(['']);
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.mastersSub$?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.userInfo) {
      this.isLoading = true;
      this.isEditable = this.userInfo.userFunctions.includes(
        VISIBILITY.PRIMANOTA_PIANODEICONTI_MASTRI_AGGIORNA
      );
      this.modalAdd = document.getElementById('modalAddMaster');
      this.modalLinkedAccounts = document.getElementById('modalLinkedAccounts');

      this.mastersSub$ = this.planOfAccountsService.mastersSub.subscribe(
        (masters) => {
          const mastersValue = Master.fromCall(masters ?? []);
          this.masters = mastersValue;
        }
      );

      if (!this.userInfo.isDirectionalUser) {
        this.loadingLedger();
      } else {
        this.isLoading = true;
        this.httpUtilisPlanOfAccounts.getAllInfos(TYPE.AGENCY).subscribe({
          next: (response) => {
            this.agenciesOptions = response['types'];
            this.isLoading = false;
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
      }
    }
  }

  loadingLedger(agency?: Agency) {
    this.isLoading = true;
    this.httpUtilisPlanOfAccounts
      .getMasters(
        this.userInfo?.agencyDescription.split(splitAgencyCode)[0]!,
        agency
      )
      .subscribe({
        next: (response) => {
          this.planOfAccountsService.mastersSub.next(response['ledgers']);
          this.showTable = true;
          this.isLoading = false;
        },
        error: (error) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
  }

  onSubmit() {
    if (this.form?.invalid) {
      this.form?.markAllAsTouched();
      return;
    }

    this.loadingLedger(this.form?.value.agency);
  }

  createNewMaster() {
    this.modifiedMaster = undefined;
    if (this.modalAdd) {
      this.modalRefAdd = new Modal(this.modalAdd, {
        keyboard: false,
        backdrop: 'static',
      });

      this.modalRefAdd.show();
    }
  }

  showMasterDetail(master: Master) {
    if (this.modalLinkedAccounts) {
      this.modifiedMaster = master;
      this.linkedAccountsService.linkedAccountsSubject.next([
        ...this.modifiedMaster.linkedAccounts,
      ]);
      this.modalRefDetail = new Modal(this.modalLinkedAccounts, {
        keyboard: false,
        backdrop: 'static',
      });
      this.modalRefDetail.show();
    }
  }

  onModify(master: Master) {
    this.modifiedMaster = master;
    if (this.modalAdd) {
      this.modalRefAdd = new Modal(this.modalAdd, {
        keyboard: false,
        backdrop: 'static',
      });

      this.modalRefAdd.show();
    }
  }

  onAgencyChange(event: DropdownChangeEvent): void {
    this.planOfAccountsService.ledgerAgency = event.value;
  }

  onDelete(master: Master) {
    if (this.userInfo) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccounts
        .deleteMaster([master])
        .pipe(
          concatMap((_deleteResponse) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              detail: 'Mastro eliminato con successo',
              key: 'br',
            });
            return this.httpUtilisPlanOfAccounts.getMasters(
              this.userInfo!.agencyDescription.split(splitAgencyCode)[0],
              this.planOfAccountsService.ledgerAgency
            );
          })
        )
        .subscribe({
          next: (getAccountResponse) => {
            this.planOfAccountsService.mastersSub.next(
              getAccountResponse['ledgers']
            );
            this.isLoading = false;
          },
          error: (error) => {
            handleError(error, this.modalService, this.messageService);
            this.isLoading = false;
          },
        });
    }
  }

  onDeleteMultipleMasters(): void {
    if (this.selectedMasters.length > 0) {
      if (this.userInfo) {
        this.isLoading = true;
        this.httpUtilisPlanOfAccounts
          .deleteMaster(this.selectedMasters)
          .pipe(
            concatMap((_deleteResponse) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                detail: 'Mastro eliminato con successo',
                key: 'br',
              });
              return this.httpUtilisPlanOfAccounts.getMasters(
                this.userInfo!.agencyDescription.split(splitAgencyCode)[0],
                this.planOfAccountsService.ledgerAgency
              );
            })
          )
          .subscribe({
            next: (getAccountResponse) => {
              this.planOfAccountsService.mastersSub.next(
                getAccountResponse['ledgers']
              );
              this.isLoading = false;
            },
            error: (error) => {
              handleError(error, this.modalService, this.messageService);
              this.isLoading = false;
            },
          });
      }
    }
  }

  getDirty = getDirty;
}
