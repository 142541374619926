<div class="commission-form mb-5">
  <form [formGroup]="this.censusService.censusForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="name">Nome e Cognome</label>
        <input
          pInputText
          type="text"
          id="name"
          formControlName="name"
          class="commission-input"
        />
      </div>

      <div *ngIf="this.userInfo?.isDirectionalUser" class="col-lg-4 col-md-12">
        <label for="agency">Agenzia</label>
        <p-autoComplete
          styleClass="autocomplete-dropdown"
          formControlName="agency"
          showClear="true"
          [dropdown]="true"
          [suggestions]="filteredValues"
          (completeMethod)="filterValues($event)"
          placeholder="Seleziona..."
          showEmptyMessage="false"
          field="description"
        />
      </div>
      <div
        class="col-lg-4 col-md-12 d-flex justify-content-start align-items-end"
      >
        <button class="btn btn-primary" type="submit">
          <strong>CERCA</strong>
        </button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="isLoading" class="text-center spinner-overlay">
  <p-progressSpinner ariaLabel="loading" />
</div>
<div class="row">
  <div class="col-6 mb-3">
    <p-button
      type="button"
      icon="pi pi-plus"
      styleClass="p-button-text"
      label="Crea"
      (click)="onAdd()"
    ></p-button>
  </div>
</div>
<div *ngIf="this.censusService.showSearchTable && !isLoading">
  <p-table
    [value]="this.censusService.censusSearchValues ?? []"
    dataKey="id"
    [scrollable]="true"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass="table-commission text-center"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name" style="width: auto">
          Nome
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="surname" style="width: auto">
          Cognome <p-sortIcon field="surname"></p-sortIcon>
        </th>
        <th pSortableColumn="active" style="width: auto">
          Attivo <p-sortIcon field="active"></p-sortIcon>
        </th>
        <th pSortableColumn="displayProducerCodes" style="width: auto">
          Codici produttori
          <p-sortIcon field="displayProducerCodes"></p-sortIcon>
        </th>
        <th
          *ngIf="this.userInfo?.isDirectionalUser"
          pSortableColumn="agency.description"
          style="width: auto"
        >
          Codice agenzia <p-sortIcon field="agency.description"></p-sortIcon>
        </th>
        <th></th>
      </tr>
      <tr>
        <th>
          <p-columnFilter field="name" matchMode="contains" [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="surname"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th></th>
        <th>
          <p-columnFilter
            field="displayProducerCodes"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th *ngIf="this.userInfo?.isDirectionalUser">
          <p-columnFilter
            field="agency.description"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-collaborator let-i="rowIndex">
      <tr>
        <td class="text-center">
          {{ collaborator.name ?? "" }}
        </td>
        <td class="text-center">
          {{ collaborator.surname ?? "" }}
        </td>
        <td class="d-flex align-items-center justify-content-center">
          <i
            *ngIf="collaborator.active"
            class="pi pi-check-circle icon-green"
          ></i>
          <i
            *ngIf="!collaborator.active"
            class="pi pi-times-circle icon-red"
          ></i>
        </td>
        <td class="text-center">
          {{ collaborator.displayProducerCodes ?? "" }}
        </td>
        <td *ngIf="this.userInfo?.isDirectionalUser" class="text-center">
          {{ collaborator.agency.description ?? "" }}
        </td>
        <td>
          <a (click)="onModify(collaborator)">
            <i class="pi pi-pen-to-square icon col-md-6" title="Modifica"></i>
          </a>

          <a (click)="onDelete(collaborator)">
            <i class="pi pi-trash icon icon col-md-6" title="Elimina"></i>
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div
  *ngIf="
    this.censusService.censusSearchValues?.length === 0 &&
    this.censusService.showSearchTable
  "
>
  <p class="text-center text">La ricerca non ha prodotto nessun risultato</p>
</div>
<app-add-modify-census-modal
  [collaborator]="this.selectedCollaborator"
></app-add-modify-census-modal>
