import { Routes } from '@angular/router';
import { SearchFormScreenComponent } from './screens/commissions/search-form-screen-commission/search-form-screen.component';
import { AddScreenComponent } from './screens/commissions/add-screen-commission/add-screen.component';
import { DetailScreenComponent } from './screens/commissions/detail-screen-commission/detail-screen.component';
import { SearchBankRecordsComponent } from './screens/bank-records/search-bank-records/search-bank-records.component';
import { DetailBankRecordsComponent } from './screens/bank-records/detail-bank-records/detail-bank-records.component';
import { authGuard } from './guard/auth.guard';
import {
  BANK_RECORD_PATH,
  CASH_CONTROL_PATH,
  CENSUS_PATH,
  COMMISSION_PATH,
  FIRST_NOTE_PATH,
  PLAN_ACCOUNT_PATH,
  REPORTING_PATH,
} from './enums/path';
import { AddBankRecordsComponent } from './screens/bank-records/add-bank-records/add-bank-records.component';
import { AddRowBankRecordScreen } from './screens/bank-records/add-row-bank-record/add-row-bank-record.component';
import { EditRowBankRecordScreen } from './screens/bank-records/edit-row-bank-record/edit-row-bank-record.component';
import { EditBankRecordComponent } from './screens/bank-records/edit-bank-record/edit-bank-record.component';
import { DetailRowBankRecordScreen } from './screens/bank-records/detail-row-bank-record/detail-row-bank-record.component';
import { LandingScreenComponent } from './screens/commissions/landing-screen/landing-screen.component';
import { SubAccountsScreenComponent } from './screens/plan-accounts/sub-accounts-screen/sub-accounts-screen.component';
import { MasterScreenComponent } from './screens/plan-accounts/master-screen/master-screen.component';
import { AccountsScreenComponent } from './screens/plan-accounts/accounts-screen/accounts-screen.component';
import { FirstNoteScreenComponent } from './screens/first-note/first-note-screen/first-note-screen.component';
import { visibilityGuard } from './guard/visibility-guard';
import { SearchCashControlScreenComponent } from './screens/cash-control/search-cash-control-screen/search-cash-control-screen.component';
import { DetailCashControlScreenComponent } from './screens/cash-control/detail-cash-control-screen/detail-cash-control-screen.component';
import { EditCashControlScreenComponent } from './screens/cash-control/edit-cash-control-screen/edit-cash-control-screen.component';
import { TableScreenComponent } from './screens/reporting/table-screen/table-screen.component';
import { SearchCensusScreenComponent } from './screens/census/search-census-screen/search-census-screen.component';
import { AgencySearchCensusScreenComponent } from './screens/census/agency-search-census-screen/agency-search-census-screen.component';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [authGuard, visibilityGuard],
    children: [
      {
        path: '',
        component: LandingScreenComponent,
      },
      {
        path: COMMISSION_PATH.SEARCH,
        component: SearchFormScreenComponent,
      },
      {
        path: COMMISSION_PATH.ADD,
        component: AddScreenComponent,
      },
      {
        path: COMMISSION_PATH.DETAIL,
        component: DetailScreenComponent,
      },
      {
        path: COMMISSION_PATH.EDIT,
        component: AddScreenComponent,
      },
      {
        path: BANK_RECORD_PATH.SEARCH,
        component: SearchBankRecordsComponent,
      },
      {
        path: BANK_RECORD_PATH.DETAIL,
        component: DetailBankRecordsComponent,
      },
      {
        path: BANK_RECORD_PATH.ADD,
        component: AddBankRecordsComponent,
      },
      {
        path: BANK_RECORD_PATH.ADD_ROW,
        component: AddRowBankRecordScreen,
      },
      {
        path: BANK_RECORD_PATH.EDIT_ROW,
        component: EditRowBankRecordScreen,
      },
      {
        path: BANK_RECORD_PATH.EDIT,
        component: EditBankRecordComponent,
      },
      {
        path: BANK_RECORD_PATH.DETAIL_ROW,
        component: DetailRowBankRecordScreen,
      },
      {
        path: PLAN_ACCOUNT_PATH.MASTERS,
        component: MasterScreenComponent,
      },
      {
        path: PLAN_ACCOUNT_PATH.ACCOUNTS,
        component: AccountsScreenComponent,
      },
      {
        path: PLAN_ACCOUNT_PATH.SUB_ACCOUNTS,
        component: SubAccountsScreenComponent,
      },
      {
        path: FIRST_NOTE_PATH.NODE,
        component: FirstNoteScreenComponent,
      },
      {
        path: CASH_CONTROL_PATH.SEARCH,
        component: SearchCashControlScreenComponent,
      },
      {
        path: CASH_CONTROL_PATH.DETAIL,
        component: DetailCashControlScreenComponent,
      },
      {
        path: CASH_CONTROL_PATH.EDIT,
        component: EditCashControlScreenComponent,
      },
      {
        path: REPORTING_PATH.TABLE,
        component: TableScreenComponent,
      },
      {
        path: CENSUS_PATH.SEARCH_COLLAB,
        component: SearchCensusScreenComponent,
      },
      {
        path: CENSUS_PATH.SEARCH_AGENCY,
        component: AgencySearchCensusScreenComponent,
      },
    ],
  },
];
