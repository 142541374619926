<div class="commission-form">
  <form [formGroup]="this.cashService.searchForm" (ngSubmit)="onSubmit()">
    <div class="row align-items-end">
      <div class="col-lg-3 col-md-12">
        <label for="name">Nome nodo</label>
        <p-dropdown
          id="name"
          formControlName="name"
          [options]="managerOptions ?? []"
          optionLabel="description"
          placeholder="Seleziona..."
          styleClass="commission-dropdown"
          showClear="true"
          [filter]="true"
          filterBy="code,description"
          [virtualScroll]="true"
          [virtualScrollItemSize]="30"
        ></p-dropdown>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="interval">Periodo</label>
        <p-calendar
          id="interval"
          formControlName="interval"
          [showIcon]="true"
          selectionMode="range"
          styleClass="calendar"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
      <div class="col-lg-1 col-md-12 d-flex align-items-end mt-2">
        <button class="btn btn-primary mx-3" type="submit">
          <strong>CERCA</strong>
        </button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="isLoading" class="text-center spinner-overlay">
  <p-progressSpinner ariaLabel="loading" />
</div>
<div *ngIf="this.cashService.showSearchTable && !isLoading" class="mt-5">
  <p-table
    [value]="this.cashService.tableValues"
    dataKey="id"
    [scrollable]="true"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass="table-commission text-center"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name" style="width: auto">
          Nome nodo
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="date" style="width: auto">
          Data <p-sortIcon field="date"></p-sortIcon>
        </th>
        <th style="width: auto">Semaforo di cassa</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-agency let-i="rowIndex">
      <tr>
        <td class="text-center">
          {{ agency.name }}
        </td>
        <td class="text-center">
          {{ agency.date | date : "dd/MM/yyyy" }}
        </td>
        <td class="d-flex align-items-center justify-content-center">
          <i *ngIf="agency.auditing" class="pi pi-check-circle icon-green"></i>
          <i *ngIf="!agency.auditing" class="pi pi-times-circle icon-red"></i>
        </td>
        <td>
          <a
            [href]="onModifyRedirect(agency)"
            (click)="onModify($event, agency)"
          >
            <i class="pi pi-pen-to-square icon col-md-6" title="Modifica"></i>
          </a>

          <a
            [href]="onDetailRedirect(agency)"
            (click)="onDetail($event, agency)"
          >
            <i
              class="pi pi-address-book icon icon col-md-6"
              title="Dettaglio"
            ></i>
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
